import React, { useEffect, useState } from 'react';
import {
    CSidebar, CSidebarBrand, CSidebarNav, CNavItem, CSidebarToggler, CFormSelect
} from '@coreui/react';
import './index.scss';
import './index.dark.scss';
import { API_URL } from 'utils/settings';
import Axios from 'axios';
import { withTranslation } from 'react-i18next';
import { icons } from 'utils/icons'
import Logo from 'assets/images/logo-sms-white.png';
import { Link, useLocation } from 'react-router-dom';
import CIcon from '@coreui/icons-react';
import { useLocalStorage } from 'utils/utils';

import { cilPlus, cilDescription, cilSettings, cilBarChart, cilAccountLogout, cilCopy } from '@coreui/icons';
import ToastComponent from 'components/ToastComponent';

function Sidebar({ t }) {

    const location = useLocation();
    const [locale, setLocale] = useLocalStorage("locale", 'ru');

    const menu = [
        {
            icon: cilDescription,
            label: t("menu.sms"),
            link: '/',
        },
        // {
        //     icon: cilPlus,
        //     label: t("menu.pay"),
        //     link: '/panel/pay',
        // },
        // {
        //     icon: cilBarChart,
        //     label: t("menu.history"),
        //     link: '/panel/balance',
        // },
        // {
        //     icon: cilSettings,
        //     label: t("menu.settings"),
        //     link: '/panel/settings',
        // },
        // {
        //     icon: cilAccountLogout,
        //     label: t("common.logout"),
        //     type: 'logout'
        // },
    ]


    return <CSidebar height="100vh">
        {/* <CSidebarBrand><img src={Logo} alt="" /></CSidebarBrand> */}
        <CSidebarNav>
            {menu.map((el) => {
                if (el.type == 'logout') {
                    return <li className="nav-item nav-item"
                    key="logoutButton"
                        onClick={() => {
                            localStorage.removeItem('token');
                            window.location.href = '/'
                        }}>
                        {/* <CNavItem key={el.label} to={el.link} className={`nav-item nav-link `}

                        > */}
                        <div className="nav-link">
                            <CIcon customClassName="nav-icon" icon={el.icon} />
                            {el.label}
                        </div>
                        {/* </CNavItem> */}
                    </li>
                }
                return <CNavItem key={el.label} to={el.link} className={`nav-item ${location.pathname == el.link ? "link-active" : ""}`} component={Link}>
                    <CIcon customClassName="nav-icon" icon={el.icon} />
                    {el.label}
                </CNavItem>
            })}
        </CSidebarNav>
        <div className="sidebar-footer">
            {/* <div
                className="sidebar-api"
                onClick={() => {
                    navigator.clipboard.writeText(localStorage.getItem('token'));
                    ToastComponent('success', t('menu.apikey_copy'))

                }}>
                <div>{t('menu.apikey')}{localStorage.getItem('token')}</div>
                <CIcon
                    icon={cilCopy}
                />

            </div> */}
            <div className="sidebar-select">
                <CFormSelect
                    aria-label="Default select example"
                    onChange={(e) => {
                        setLocale(e.target.value);
                    }}
                    value={locale}
                    options={[
                        { label: t('language.ru'), value: 'ru' },
                        { label: t('language.en'), value: 'en' },
                    ]}
                />
            </div>
        </div>
    </CSidebar >

}

export default withTranslation()(Sidebar)