import React, { useState, useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import DataTable from "react-data-table-component";
import CIcon from '@coreui/icons-react';
import {
    cilPlus, cilArrowTop, cibVk, cilClock, cilPhone, cilPen, cilX, cilCheckAlt, cilTrash
} from '@coreui/icons';
import Axios from 'axios';
import { API_URL } from 'utils/settings';
import { getDate } from 'utils/utils';
import { CSpinner, CTooltip } from '@coreui/react';
import ToastComponent from 'components/ToastComponent';
import { CButton } from '@coreui/react';
import TablePagination from 'components/TablePagination';
import Filters from 'components/Filters';
import { toast } from 'react-toastify';
import ByteConverter from "byte-converter-react";

function UsersTable(props) {
    const { getAccounts, users, setUsers, total, setTotal, page, perPage, setPage, setPerPage, isLoading, filters, setFilters } = props;

    useEffect(() => {
        getAccounts(page, perPage, filters);
    }, [page, perPage, filters])

    const isInt = (n) => {
        return n % 1 === 0;
    }
    const renderGB = (value)  => {
        let result = value / 1024 / 1024 / 1024;
        
        return isInt(result) ? result : parseFloat(result).toFixed(3)
    }

    // const renderStatus = (status) => {
    //     const statusIcons = {
    //         waiting_phone: cilPhone,
    //         waiting_sms: cilPen,
    //         canceled: cilX,
    //         finished: cilCheckAlt,
    //     }

    //     if (statusIcons[status]) {
    //         return <CTooltip content={<>{t(`table.${status}`)}</>}>
    //             <CIcon icon={statusIcons[status]} />
    //         </CTooltip>
    //     } else {
    //         return status
    //     }

    // }
    // const renderIcon = (iconName, country) => {
    //     const icons = {
    //         'vk': cibVk,
    //     }
    //     if (!icons[iconName]) {
    //         return <>{`${iconName}`} <div className={`flag flag-${country.alpha2}`} /></>
    //     } else {
    //         return <><CIcon icon={icons[iconName]} /> <div className={`flag flag-${country.alpha2}`} /></>
    //     }
    // }
    const deleteUser = (id) => {
        Axios.delete(`${API_URL}/sub-account/${id}`)
            .then(res => {
                getAccounts(page, perPage)
            }).catch(err => {
                toast.success(t('common.file_failed'))
            })
    }
    const { t } = useTranslation()
    const columns = [

        {
            id: 'subAccountName',
            name: t('common.username'),
            center: true,
            selector: (row) => row.subAccountName || '—',
            sortable: true,
        },
        {
            id: 'bandWidth',
            name: t('common.bandWidth'),
            center: true,
            selector: (row) => row.bandWidth ? `${renderGB(row.bandWidth)} ${t('common.gb')}` : '—',
            sortable: true,
        },
        {
            id: 'bandWidthLimit',
            name: t('common.bandWidthLimit'),
            center: true,
            selector: (row) => row.bandWidthLimit ? `${renderGB(row.bandWidthLimit)} ${t('common.gb')}` : '—',
            sortable: true,
        },
        {
            id: 'createdAt',
            name: t('common.createdAt'),
            center: true,
            selector: (row) => new Date(row.createdAt).toLocaleString() || '—',
            sortable: true,
        },
        {
            id: 'valid',
            name: t('common.valid'),
            center: true,
            selector: (row) => row.valid ? <CIcon icon={cilCheckAlt} /> : <CIcon icon={cilX} />,
            sortable: true,
        },
        {
            id: 'delete',
            name: t('common.actions'),
            center: true,
            selector: (row) => <CButton onClick={() => { deleteUser(row.id) }} size="sm" variant="outline" color="danger" style={{ cursor: 'pointer' }}>
                <CIcon icon={cilTrash} />
            </CButton>,
            sortable: true,
        },

    ]
    return <>
        <h6>
            <div>{t('heading.subAccounts')} — {total}</div>

        </h6>
        <DataTable
            columns={columns}
            data={users}
            sortIcon={<CIcon icon={cilArrowTop} />}
            noDataComponent={t('table.notFound')}
            progressPending={isLoading}
            progressComponent={<CSpinner color="primary" />}
        />
        {users?.length > 0 && <TablePagination
            page={page}
            setPage={setPage}
            total={total}
            perPage={perPage}
            setPerPage={setPerPage}
        />}
    </>
}

export default UsersTable