import React, { useState, useEffect } from 'react';
import {
    CInputGroup as InputGroup,
    CInputGroupText as InputGroupText,
    CFormInput as Input,
    CNav,
    CNavItem,
    CNavLink,
    CTabPane,
    CTabContent,
    CFormSelect,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cilUser, cilHttps } from '@coreui/icons';
import './index.scss';
import { Link } from 'react-router-dom';
import Axios from 'axios';
import { API_URL } from 'utils/settings';
import { useTranslation } from 'react-i18next';
import ToastComponent from 'components/ToastComponent';


function AuthPage() {
    const [activeKey, setActiveKey] = useState(1)
    const [user, setUser] = useState('');
    const [pass, setPass] = useState('');
    const [currency, setCurrency] = useState("RUB")
    const { t } = useTranslation()

    const login = () => {
        Axios.post(`${API_URL}/login_check`, {
            email: user,
            password: pass,
        })
            .then(function (response) {
                if (response.data.token) {
                    localStorage.setItem('token', response.data.token)
                    window.location.href = '/';

                }
            }).catch(error => {
                ToastComponent('error', error.response.data.message)
            })

    }
    const register = () => {
        Axios.post(`${API_URL}/register`, {
            email: user,
            password: pass,
            currency: currency,
        })
            .then(function (response) {
                if (response.data.userId) {
                    ToastComponent('success', "Регистрация прошла успешно")
                    setActiveKey(1);
                    // localStorage.setItem('token', response.data.token)
                    // window.location.href = '/partner';
                }
            }).catch(error => {
                ToastComponent('error', error.response.data.message)
            })
    }
    return (
        <section className="auth bg-light min-vh-100 d-flex flex-row align-items-center">
            <div className="container">
                <div className="auth__form">
                    <div className="card p-4 w-50 m-auto">
                        <div className="card-body">
                            <CNav variant="tabs" role="tablist">
                                <CNavItem>
                                    <CNavLink
                                        href="#!"
                                        active={activeKey === 1}
                                        onClick={() => setActiveKey(1)}
                                    >
                                        {t('common.login')}
                                    </CNavLink>
                                </CNavItem>
                                {/* <CNavItem>
                                    <CNavLink
                                        href="#!"
                                        active={activeKey === 2}
                                        onClick={() => setActiveKey(2)}
                                    >
                                        {t('common.registration')}
                                    </CNavLink>
                                </CNavItem> */}
                            </CNav>
                            <br />
                            <CTabContent>
                                <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 1}>
                                    <h3>{t('login.title')}</h3>
                                    {/* <h6 className="mb-3 fw-light">{t('login.subtitle')}</h6> */}
                                    <InputGroup className="mb-3">
                                        <InputGroupText id="user">
                                            <CIcon icon={cilUser} />
                                        </InputGroupText>
                                        <Input placeholder={t('common.username')} onChange={(e) => setUser(e.target.value)} aria-label="Имя пользователя" aria-describedby="user" />
                                    </InputGroup>
                                    <InputGroup className="mb-3">
                                        <InputGroupText id="password">
                                            <CIcon icon={cilHttps} />
                                        </InputGroupText>
                                        <Input placeholder={t("common.password")} onChange={(e) => setPass(e.target.value)} type="password" aria-label="Пароль" aria-describedby="password" />
                                    </InputGroup>
                                    <div className="d-flex justify-content-end">
                                        <button onClick={() => login()} className="btn btn-primary mx-xl-3">{t("common.login")}</button>
                                        <Link to="/" className="btn btn-secondary">{t("common.cancel")}</Link>
                                    </div>
                                </CTabPane>
                            </CTabContent>
                            {/* <CTabPane role="tabpanel" aria-labelledby="home-tab" visible={activeKey === 2}>
                                <h3>{t('common.registration')}</h3>
                                <InputGroup className="mb-3">
                                    <InputGroupText id="user">
                                        <CIcon icon={cilUser} />
                                    </InputGroupText>
                                    <Input placeholder={t("common.username")} onChange={(e) => setUser(e.target.value)} aria-label="Имя пользователя" aria-describedby="user" />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroupText id="password">
                                        <CIcon icon={cilHttps} />
                                    </InputGroupText>
                                    <Input placeholder={t("common.password")} onChange={(e) => setPass(e.target.value)} type="password" aria-label="Пароль" aria-describedby="password" />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <CFormSelect
                                        aria-label="Set currency"
                                        placeholder="Set currency"
                                        value={currency}
                                        onChange={(event) => setCurrency(event.target.value)}
                                        options={[
                                            { label: 'RUB', value: 'RUB' },
                                            { label: 'USD', value: 'USD' },
                                        ]}
                                    />
                                </InputGroup>
                                <div className="d-flex justify-content-end">
                                    <button onClick={() => {
                                        register()
                                    }} className="btn btn-primary mx-xl-3">{t("common.login")}</button>
                                    <Link to="/" className="btn btn-secondary">{t("common.cancel")}</Link>
                                </div>
                            </CTabPane> */}

                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AuthPage