import React, { useState, useEffect } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import Sidebar from 'components/Sidebar';

// import PaymentTable from '../BalanceHistory/PaymentTable';
import CIcon from '@coreui/icons-react';
import {
    cilPlus
} from '@coreui/icons';
import {  CFormInput } from '@coreui/react';
import Axios from 'axios';
import { API_URL } from 'utils/settings';
import ChooseCountries from 'components/ChooseCountries';
import UsersTable from './UsersTable';
import { toast } from 'react-toastify';

function MainPage(props) {
    const { t } = useTranslation()
    const [users, setUsers] = useState([]);
    const [countries, setCountries] = useState([]);
    const [total, setTotal] = useState(0)
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    const [filters, setFilters] = useState([
        {
            name: 'statuses',
            items: ['all', 'waiting_phone', 'waiting_sms', 'finished', 'canceled'],
            type: 'multiselect',
            value: null,
        },
        {
            name: 'dateFrom',
            type: 'date',
            value: null,
        },
        {
            name: 'dateTo',
            type: 'date',
            value: null,
        },
    ])
    const getAccounts = (page, perPage, filters) => {
        setIsLoading(true);

        let params = {
            perPage: perPage,
            page: page,
        }
        // filters.forEach((el) => {

        //     if (el.value && el.value !== 'all') {
        //         if (el.type == 'multiselect') {
        //             params = { ...params, [el.name]: [el.value] };
        //             return
        //         }
        //         params = { ...params, [el.name]: el.value };
        //     }
        // })

        Axios.get(`${API_URL}/sub-accounts`
            , {
                params: params
            }
        ).then(function (response) {
            setIsLoading(false);

            if (response.data.subAccounts?.length > 0) {
                setUsers(response.data.subAccounts);
                setTotal(response.data.total);
            } else {
                setUsers([]);
                setTotal(0)
            }
        }).catch(error => {
            setIsLoading(false);
        })
    }
    const sendFile = (event) => {
        let headers = {
            headers: {
                enctype: "application/x-www-form-urlencoded"
            }
        }
        const data = new FormData()
        data.append('subAccounts', event.target.files[0])
        Axios.post(`${API_URL}/sub-account/upload`,
            data,
            headers
        )
            .then(res => {
                toast.success(t('common.file_success'))
                getAccounts(page, perPage)
            }).catch(err => {
                toast.success(t('common.file_failed'))
            })
    }

    return <>
        <div className="page">
            <Sidebar />
            <div className="container container-panel">
                <div className="columns">
                    <div className="columns__item shadow-block services-table">
                        <div className="mb-3 d-flex justify-content-end">
                            <div>
                                <CFormInput type="file" onChange={(event) => sendFile(event)} id="formFile" />
                            </div>
                        </div>

                        <UsersTable
                            users={users}
                            setUsers={setUsers}
                            getAccounts={getAccounts}
                            total={total}
                            setTotal={setTotal}
                            page={page}
                            perPage={perPage}
                            setPage={setPage}
                            setPerPage={setPerPage}
                            isLoading={isLoading}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default MainPage