import React from 'react';
import './index.scss';
import CountrySelect from './select';

function ChooseCountries(props) {
    const { countries } = props;
    return <div className="choose-countries">
        <CountrySelect countries={countries} />
        <CountrySelect countries={countries} />
        <CountrySelect countries={countries} />
        <CountrySelect countries={countries} />
        <CountrySelect countries={countries} />
        <CountrySelect countries={countries} />
    </div>
}

export default ChooseCountries