// import logo from './logo.svg';
import React, { useEffect } from 'react'
// import "@coreui/coreui/scss/coreui.scss"
import './App.scss';
import AppRouter from './core/router';
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-confirm-alert/src/react-confirm-alert.css";
import { useLocalStorage } from 'utils/utils';
import classNames from "classnames";
import Favicon from 'react-favicon'
import Fav from "./assets/images/favicon.png";
import i18n from 'utils/i18n';
import ToastComponent from 'components/ToastComponent';
import { useTranslation } from 'react-i18next';


function App() {
    const [theme] = useLocalStorage("theme", 'light');
    const [locale, setLocale] = useLocalStorage("locale", 'ru');
    const { t } = useTranslation()

    useEffect(() => {
        i18n.changeLanguage(locale);
    }, [locale])
    Axios.defaults.headers.common['authorization'] = `Bearer ${localStorage.getItem('token')}`
    // Axios.interceptors.response.use(function (response) {
    //     return response;
    // }, function (error) {
    //     if (error.response.data.code == 401)  {
    //         return
    //     }
    //     if (error.response.data.errors) {
    //         const errors = error.response.data.errors
    //         errors.map((el) => {
    //             ToastComponent('error', el.detail)
    //         })
    //     } else {
    //         ToastComponent('error', t('common.notifications.fetch_data_error'))
    //     }
    // });

    return (
        <div
            className={classNames({
                "app": true,
                [`${theme}`]: true,
            })}
        >
            <Favicon url={Fav} />
            <AppRouter locale={locale} />
            <ToastContainer />
        </div>
    );
}

export default App;
