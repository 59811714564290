import React from 'react';
import './index.scss';
import { CFormSelect, CPagination } from '@coreui/react';
import ReactPaginate from 'react-paginate';

function TablePagination(props) {
    const { total, perPage, page, setPage, setPerPage } = props;
    return <div className="table-pagination">
        <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={(page) => setPage(page.selected + 1)}
            pageRangeDisplayed={2}
            pageCount={Math.ceil(total / perPage)}
            previousLabel="<"
            renderOnZeroPageCount={null}
        />
        <CFormSelect

            onChange={(e) => {
                setPerPage(e.target.value);
            }}
            value={perPage}
            options={[
                { label: `Per page: 5`, value: 5 },
                { label: `Per page: 10`, value: 10 },
                { label: `Per page: 15`, value: 15 },
                { label: `Per page: 20`, value: 20 },
            ]}
        />
    </div>
}

export default TablePagination