import AuthPage from "pages/AuthPage";
import MainPage from "pages/MainPage";
import React, { useEffect, useState } from "react";
import Axios from 'axios';
import { API_URL } from 'utils/settings';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
// import NotFound from "pages/NotFound";


function AppRouter() {
    const [authorized, setAuthorized] = useState(false);
    const [isFetched, setIsFetched] = useState(false);
    console.log(window.location.href.includes('auth'))
    useEffect(() => {
        Axios.get(`${API_URL}/sub-accounts`)
            .then(function (response) {
                setAuthorized(true)
                // if (response.data.subAccounts) {
                //     // setMenuList(response.data.items)
                //     setAuthorized(true)
                // } else {
                //     setAuthorized(false)
                // }
            }).catch(error => {
                if (!window.location.href.includes('auth')) {
                    window.location.href = "/auth"
                }

                setAuthorized(false)
            })
    }, [])

    // const renderItems = () => {
    //     if (!authorized && isFetched) {
    //         return <Route exact path="/panel/main">
    //             <AuthPage />
    //         </Route>
    //     }
    //     return
    // }

    return (
        <Router>
            <Switch>
                <Route exact path="/auth">
                    <AuthPage />
                </Route>
                <Route exact path="/">
                    <MainPage authorized={authorized} />
                </Route>
                {/* <Route exact path="/panel/main">
                    <AdminMainPage />
                </Route> */}
                {/* <Route exact path="/panel/pay">
                    <AddBalancePage />
                </Route>
                <Route exact path="/panel/balance">
                    <BalanceHistoryPage />
                </Route>
                <Route exact path="/payments/success">
                    <Redirect to="/panel/balance?success=true" />
                </Route>
                <Route path="*">
                    <NotFound authorized={authorized} />
                </Route> */}
            </Switch>
        </Router>
    )
}

export default AppRouter